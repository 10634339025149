import React, { useContext, useEffect, useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import '../../css/AgregarSuspensionModal.css';
import Button from '../../components/Button';
import { getSuspensionById, postSuspension, putSuspension, getPersona } from '../../utils/peticionesApi';
import { NewContext } from '../../utils/ContextProvider';
import { useParams } from "react-router-dom";

const AgregarSuspensionModal = ({
    rol,
    closeModalSuspension,
    suspension,
    handleSubmitSuspension,
}) => {
    const { getAlert, getId, selectedEmployee } = useContext(NewContext);
    const [suspensionDate, setSuspensionDate] = useState('');
    const [observations_suspension, setObservations] = useState('');
    const [AppliedDays, setAppliedDays] = useState('');
    const [id_reason, setSelectedMotivo] = useState('');
    const [selectedEmpleadoId, setSelectedEmpleadoId] = useState('');
    const [empleados, setEmpleados] = useState([]);
    const [nombre, setNombre] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [active, setActive] = useState(false);
    const { id } = useParams()

    useEffect(() => {
        getSuspensionById(getId);

        if (suspension) {
            setSuspensionDate(formatDateForInput(suspension.suspension_date));
            setObservations(suspension.observations_suspension);
            setSelectedMotivo(suspension.id_reason);
            setSelectedEmpleadoId(suspension.uuid_employee);
            setNombre({
                name: suspension.employee_first_name,
                lastname: suspension.employee_last_name,
                id: suspension.uuid_employee,
            });
            setActive(suspension.active);
            setIsEditing(true);
        } else {
            setSuspensionDate('');
            setObservations('');
            setSelectedMotivo('');
            setNombre('');
            setSelectedEmpleadoId(id);
            setIsEditing(true);
        }
    }, [suspension]);

    const formatDateForInput = (dateArray) => {
        if (!Array.isArray(dateArray) || dateArray.length !== 3) {
            return '';
        }
        const [year, month, day] = dateArray;
        return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    };

    // Nueva función para formatear la fecha a dd-MM-yyyy
    const formatDateToDDMMYYYY = (date) => {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
    };

    const handleMotivoChange = (event) => {
        setSelectedMotivo(event.target.value);
    };

    const handleEstadoChange = (event) => {
        setActive(event.target.value);
    };

    const handleEmpleadoChange = (event) => {
        const empleadoSeleccionado = empleados.find(empleado => empleado.id === event.target.value);
        if (empleadoSeleccionado) {
            const { person, id } = empleadoSeleccionado;
            setNombre({ name: person.name, lastname: person.lastname, id });
            setSelectedEmpleadoId(event.target.value);
        }
    };

    const onSubmit = async () => {
        const formDataToSendSuspension = {
            applied_days: AppliedDays,
            reason: observations_suspension,
            status: active ? 'Aprobada' : 'Pendiente',
            suspension_date: formatDateToDDMMYYYY(suspensionDate), // Formato dd-MM-yyyy
            uuid_employee: suspension?.uuid_employee || selectedEmpleadoId // Usa el ID seleccionado si es una nueva suspensión
        };

        try {
            if (!suspension) {
                const response = await postSuspension(formDataToSendSuspension);
                console.log("Suspensión agregada correctamente:", response);
            } else {
                const response = await putSuspension(formDataToSendSuspension);
                console.log("Editando suspensión existente:", formDataToSendSuspension);
            }

            handleSubmitSuspension(formDataToSendSuspension);
            closeModalSuspension();

        } catch (error) {
            console.error("Error al enviar la suspensión:", error);
        }
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    return (
        <>
            <div className='contenedor-modal-suspensiones'>
                <div>
                    <div>
                        <InputLabel id="suspension-label">Solicitante</InputLabel>
                        <p>{selectedEmployee?.person.name} {selectedEmployee?.person.lastname}</p>
                    </div>
                </div>
                <div className="contenedor-fecha-inicio">
                    <TextField
                        id="startDate"
                        label="Fecha de Suspension"
                        type="date"
                        value={suspensionDate}
                        onChange={(event) => setSuspensionDate(event.target.value)}
                        className="customTextField"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={!isEditing}
                        variant="outlined"
                    />
                </div>
                <div className="selectContainer">
                    <FormControl>
                        <InputLabel id="estado-label">Estado</InputLabel>
                        <Select
                            labelId="estado-label"
                            id="estado"
                            value={active}
                            onChange={handleEstadoChange}
                            className="customSelect-suspensiones"
                            disabled={!isEditing}
                            variant="outlined"
                        >
                            <MenuItem value={true}>Aprobado</MenuItem>
                            <MenuItem value={false}>Pendiente</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="contenedor-observaciones">
                    <TextField
                        id="diasAplicados"
                        label="Días aplicados"
                        value={AppliedDays}
                        onChange={(event) => setAppliedDays(event.target.value.replace(/\D/g, ''))} // Solo permite números
                        className="customTextField"
                        disabled={!isEditing}
                        variant="outlined"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Acepta solo números en dispositivos móviles
                    />
                </div>

                <div className="contenedor-observaciones">
                    <TextField
                        id="observations"
                        label="Observaciones"
                        multiline
                        minRows={4}
                        value={observations_suspension}
                        onChange={(event) => setObservations(event.target.value)}
                        className="customTextField"
                        disabled={!isEditing}
                        variant="outlined"
                    />
                </div>

            </div>
            <div className="button-suspension">
                <button className="btn-cancelar" onClick={closeModalSuspension}>Cancelar</button>
                {suspension && !isEditing && (
                    <button className="btn-editar" onClick={handleEditClick}>Editar</button>
                )}
                {isEditing && (
                    <button className="btn-agregar" onClick={onSubmit}>{suspension ? "Guardar cambios" : "Agregar suspensión"}</button>
                )}
            </div>
        </>
    );
};

export default AgregarSuspensionModal;