import React, { useContext } from "react";
import "../css/CardBirthday.css";
import { NewContext } from "../utils/ContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCakeCandles } from "@fortawesome/free-solid-svg-icons";

const CardBirthday = ({ birthdays }) => {
  const array = []
  array.slice()
  const { getCompany, selectedEmployee } = useContext(NewContext);
  const ulrPicturePersonal = "https://www.softnik.cloud/awsProfile/getProfile";

  return (
    <div className="contenedorBirthdays">
      {birthdays ? birthdays.map(birth=>(
       
        <div key={birth.id} className="contenedorCardBirthday">
            <FontAwesomeIcon icon={faCakeCandles} className="iconBirthday"/>
            <img
              src={`${ulrPicturePersonal}?uuid=${birth.id}&company=${getCompany}`}
              className="img-birthdays"
              alt={`${birth.id}`}
            />
            <p className="nombreUserBirthday">{birth.person.name} {birth.person.lastname}</p>
            <p className="birthdayUser">{birth.person.date_of_birth.split('-').slice(0,2).join('-')}</p>
        </div>
       
      )) : null}
    </div>
  );
};

export default CardBirthday;
