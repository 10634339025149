import { createContext, useState } from "react";
import { getDateUser, getRegisters, getUUID } from "./peticionesApi";

export const NewContext = createContext([]);

const initialValuesAlert = {
  msj: "",
  tipe: "",
};

export const ContextProvider = ({ children }) => {
  const [flag, setFlag] = useState(window.innerWidth < 1080 ? false : true);
  const [getRol, setRol] = useState();
  const [getToken, setToken] = useState();
  const [getId, setId] = useState();
  const [getEmail, setEmail] = useState();
  const [getCompany, setCompany] = useState();
  const [valuesAlert, setValuesAlert] = useState(initialValuesAlert);
  const [flagAlert, setFlagAlert] = useState(false);
  const [presente, setPresente] = useState(null);
  const [contador, setContador] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [empleadoNameRegister, setEmpleadoNameRegister] = useState()
  const [month, setMonth] = useState(new Date().getMonth());

  const setStates = () => {
    setFlag(window.innerWidth < 1080 ? false : true);
    setRol(null);
    setToken(null);
    setId(null);
    setEmail(null);
    setCompany(null);
    setValuesAlert(initialValuesAlert);
    setFlagAlert(false);
    setPresente(0);
    setSelectedEmployee(null);
  };

  const setUserSession = async (token, rol, email, company) => {
    setToken(token);
    setRol(rol[0]);
    setEmail(email);
    setCompany(company);

    // Funcion para eliminar el token a los 30 minutos
    /* setTimeout(() => {
            sessionStorage.removeItem('access_token');
            console.log('Token eliminado después de 30 minutos');
        }, 30 * 60 * 1000);  */

    if (
      rol[0] === "ROLE_ADMIN" ||
      rol[0] === "ROLE_RRHH" ||
      rol[0] === "ROLE_SUPERVISOR"
    ) {
      const { data } = await getRegisters();
      if (data) {
        setPresente(data);
        setContador(data.totalElements);
      }
    }
    if (email && token) {
      try {
        const { data } = await getUUID(email);
        if (data) {
          setId(data.id);
          setSelectedEmployee(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const controlToken = async (tokenRequest) => {
    try {
      const username = sessionStorage.getItem("username");
      const { data } = await getDateUser(username);
      if (data) {
        setUserSession(tokenRequest, data.appUserRoles, data.email, data.company);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const getAlert = ({ msj, tipe = "succes" }) => {
    setValuesAlert({ msj, tipe });
    setFlagAlert(true);
  };

  const values = {
    flag,
    setFlag,
    getToken,
    getRol,
    getId,
    getEmail,
    getCompany,
    setUserSession,
    controlToken,
    valuesAlert,
    setValuesAlert,
    getAlert,
    setFlagAlert,
    flagAlert,
    presente,
    setPresente,
    contador,
    setContador,
    selectedEmployee,
    setStates,
    month,
    setMonth,
    empleadoNameRegister,
    setEmpleadoNameRegister,
  };
  return <NewContext.Provider value={values}>{children}</NewContext.Provider>;
};
