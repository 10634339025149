import { React, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { NewContext } from "../utils/ContextProvider";
import { getMiPerfil } from "../utils/peticionesApi";
import { URL_PICTURE } from "../utils/urls";
import '../css/MiPerfil.css'
import Button from '../components/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faAsterisk, faEnvelope, faFolder, faLocationDot, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";

function MiPerfil() {
  const { getId, getCompany } = useContext(NewContext);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const history = useNavigate(); // Inicializa la instancia de useHistory
  console.log(getId)
  const handleShow = () => {
    history("/editarperfil"); // Redirige a la página editarperfil al hacer clic
  };

  const listarMiPerfil = async () => {
    const response = await getMiPerfil(getId);
    setSelectedEmployee(response.data);
  };
 
  useEffect(() => {
    if (getId) {
      listarMiPerfil();
    }
  }, [getId]);

  return (
    <div
      className="miperfil-container"
    >
      <p className="titulo">Mi perfil</p>

      <div className="subcontenedor-miperfil">
        {getId && (
          <div className="contenedor-imgPerfil">
            <img
              src={`${URL_PICTURE}getProfile?uuid=${getId}&company=${getCompany}`}
              className="img-miperfil"
              alt="fotoPerfil"
            />
            <div className="contenedor-button-img-perfil">
              <Button msj='Cambiar imagen' />
              <Button tipe='red' msj='Eliminar imagen' />
            </div>
          </div>
        )}
        <div className="contenedor-datos-miperfil">
          {
            selectedEmployee &&
            <div className="contenedor-datos-perfil">
              <div className="contenedor-dato-perfil" title='Nombre'>
                <FontAwesomeIcon icon={faUser} className="icon-miprofile" />
                <p>{`${selectedEmployee.person.name} ${selectedEmployee.person.lastname}`}</p>
              </div>
              <div className="contenedor-dato-perfil" title="dni">
                <FontAwesomeIcon icon={faAddressCard} className="icon-miprofile"/>
                <p>{`DNI: ${selectedEmployee.person.national_id}`}</p>
              </div>
              <div className="contenedor-dato-perfil" title="direccion">
                <FontAwesomeIcon icon={faLocationDot} className="icon-miprofile"/>
                <p>{`${selectedEmployee.address.street} ${selectedEmployee.address.number}`}</p>
              </div>
              <div className="contenedor-dato-perfil" title="telefono">
                <FontAwesomeIcon icon={faPhone} className="icon-miprofile"/>
                <p>{`${selectedEmployee.cell_phone ? selectedEmployee.cell_phone : 'Sin contacto'}`}</p>
              </div>
              <div className="contenedor-dato-perfil" title="contacto de emergencia">
                <FontAwesomeIcon icon={faAsterisk} className="icon-miprofile"/>
                <p>{`${selectedEmployee.name_emergency_contact}`}</p>
              </div>
              <div className="contenedor-dato-perfil" title="email">
                <FontAwesomeIcon icon={faEnvelope} className="icon-miprofile"/>
                <p>{`${selectedEmployee.email}`}</p>
              </div>
              <div className="contenedor-btnEdit">
                <Button msj='Editar datos de perfil' tipe="link" />
                <Button tipe="sinBg" msj='Documentacion'>
                  <FontAwesomeIcon icon={faFolder} className="icon-miprofile" />
                </Button>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default MiPerfil;
