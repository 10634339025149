import { React, useContext, useEffect, useState } from "react";
import "../css/Objetivos.css";
import CustomPagination from "../components/CustomPagination";
import { NewContext } from "../utils/ContextProvider";
import AgregarObjetivoModal from '../forms/Objetivo/AgregarObjetivoModal'
import { getObjetivos } from "../utils/peticionesApi";
import { useNavigate } from "react-router-dom";
import OptionComponentObjetivos from "../components/OptionComponentObjetivos";

const Objetivos = () => {
  const { getToken } = useContext(NewContext);
  const [objetivos, setObjetivos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showPopup] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate()

  const objetivosPerPage = 20;

  const listarObjetivos = async () => {
    try {
      const response = await getObjetivos();
      setObjetivos(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!getToken) return;
    if (searchTerm !== "") {
      listarObjetivos(0, 3000);
    } else {
      // Si no hay término de búsqueda, cargar todas los objetivos paginadas
      listarObjetivos(objetivosPerPage);
    }
  }, [searchTerm, showPopup, menuVisible, getToken]);

  const filteredobjetivos =
    objetivos?.filter((objetivo) => {
      const fullDireccion =
        objetivo.address?.street + " " + objetivo.address?.number;
      return (
        objetivo.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        objetivo.city.name.includes(searchTerm) ||
        fullDireccion.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }) || [];

  const TableRow = ({
    objetivo,
    index,
  }) => (
    <tr className="itemTablaObjetivo" key={index} onClick={()=> navigate(`/objetivo/${objetivo.id}`)}>
      <td>{objetivo.name}</td>
      <td>
        {objetivo.address.street}
        {objetivo.address.number}
      </td>
      <td>{objetivo.city.name}</td>
      <td style={{ textAlign: "center" }}>
        <span className={objetivo.active === true ? "itemActive" : "itemInactive"}>{objetivo.active === true ? "Activo" : "Inactivo"}</span>
      </td>
    </tr>
  );

  if(menuVisible) return <AgregarObjetivoModal setMenuVisible={setMenuVisible}  menuVisible={menuVisible}/>

  return (
    <div className="contenedorObjetivo">
      <p className="titulo"> Empresas </p>

      <OptionComponentObjetivos
        type="text"
        placeHolder="Buscar por nombre o dirección"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        setMenuVisible={setMenuVisible}
        menuVisible={menuVisible}
      />

      <div className="data-table">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Dirección</th>
              <th>Ciudad</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {searchTerm !== "" ? (
              filteredobjetivos.length === 0 ? (
                <tr>
                  <td colSpan="4">No se encontraron resultados.</td>
                </tr>
              ) : (
                filteredobjetivos.map((objetivo, index) => (
                  <TableRow
                    key={index}
                    objetivo={objetivo}
                    index={index}
                   
                  />
                ))
              )
            ) : (
              objetivos?.map((objetivo, index) => (
                <TableRow
                  key={index}
                  objetivo={objetivo}
                  index={index}
                />
              ))
            )}
          </tbody>
        </table>
        <div className="d-flex justify-content-center mt-4">
          <CustomPagination currentPage={0} totalPages={totalPages} />
        </div>
      </div>
    </div>
  );
};

export default Objetivos;