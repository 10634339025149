import React, { useContext, useEffect, useState } from "react";
import "../css/Reloj.css";
import CustomPagination from "../components/CustomPagination";
import { NewContext } from "../utils/ContextProvider";
import OptionComponent from '../components/OptionComponent';
import { getTransaction } from "../utils/peticionesApi";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getExportWithDetail, getExportWithOutDetail } from "../utils/peticionesApi";
import SelectEmpleados from "../components/SelectEmpleados";
import Button from "../components/Button";

const Reloj = () => {
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());;
    const [uuid, setUuid] = useState(null);
    const dateStringInicio = selectedStartDate.toDateString();
    const formattedDateInicio = formatDate(dateStringInicio);
    const dateStringFin = selectedEndDate.toDateString();
    const formattedDateFin = formatDate(dateStringFin);
    const { getToken, getId, getAlert, getCompany } = useContext(NewContext);
    const [transactions, setTransactionss] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [modalView, setModalView] = useState(false);
    const [modalViewExport, setModalViewExport] = useState(false);
    const [detalle, setDetalle] = useState(true)
    const [detail, setDetail] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [dateExport, setDateExport] = useState({
        exportDesde: null,
        exportHasta: null
    })
    const employeesPerPage = 20;
    const [selectRegistry, setSelectRegistry] = useState(null);

    function formatDate(dateString) {
        const months = {
            Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
            Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
        };
        const [nameDay, monthStr, day, year] = dateString.split(' ');
        const month = months[monthStr];
        return `${year}-${month}-${day}`;
    }

    const listTransactions = async (currentPage, employeesPerPage) => {
        try {
            const companyParam = getCompany === 'folks' ? 1 : 0;
            const response = await getTransaction(formattedDateInicio, formattedDateFin, currentPage, employeesPerPage, companyParam);
            setTransactionss(response.data.data);
            console.log(response.data.data);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error("Error al obtener transacciones:", error);
        }
    };

    useEffect(() => {
        if (getToken && getId) {
            listTransactions(currentPage);
        }
    }, [selectRegistry, currentPage, selectedEndDate]);


    const filterTransaction = transactions.filter(transaction => {
        const fullName = transaction.name + ' ' + transaction.lastname;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const TableRow = ({ transaction }) => {
        const date = new Date(transaction.upload_time);
        const formattedDate = date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });

        return (
            <tr>
                <td style={{ textAlign: 'center' }}>{transaction.first_name}</td>
                <td style={{ textAlign: 'center' }}>{transaction.last_name}</td>
                <td style={{ textAlign: 'center' }}>{formattedDate}</td>
                <td style={{ textAlign: 'center' }}>{formattedTime}</td>
                <td style={{ textAlign: 'center' }}>{transaction.terminal_alias}</td>
            </tr>
        );
    };

    const handleDateChange = newDates => {
        if (newDates.length === 1) {
            setSelectedStartDate(newDates[0]);
            setSelectedEndDate(null);
        } else if (newDates.length === 2) {
            setSelectedStartDate(newDates[0]);
            setSelectedEndDate(newDates[1]);
            setModalView(!modalView);
        }
    };

    const handleExportChange = e => {
        const [año, mes, dia] = e.target.value.split('-');
        setDateExport({ ...dateExport, [e.target.name]: `${dia}/${mes}/${año}` });
    };


    const submitExport = async () => {
        if (!dateExport.exportDesde || !dateExport.exportHasta) return getAlert({ msj: `No se ingresó la fecha.`, tipe: 'error' });
        try {
            const peticion = detail ? getExportWithDetail(getCompany, dateExport.exportDesde, dateExport.exportHasta, uuid) : getExportWithOutDetail(getCompany, dateExport.exportDesde, dateExport.exportHasta, uuid);
            const res = await peticion;
            const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'asistencias.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="contenedor-dispositivo">
            <div>
                <p className="titulo">{"Reloj de Asistencia"}</p>
                <OptionComponent
                    type="text"
                    placeHolder="Buscar por nombre, apellido o área"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    modalView={modalView}
                    setModalView={setModalView}
                    modalViewExport={modalViewExport}
                    setModalViewExport={setModalViewExport}
                />
                {modalView ? (
                    <div className="modalCalendar">
                        <Calendar onChange={handleDateChange} value={selectedEndDate ? [selectedStartDate, selectedEndDate] : selectedStartDate} selectRange={true} />
                    </div>
                ) : null}

                {modalViewExport ? (
                    <div className="modalExport">
                        <div className="contenedorBotonesFiltroEmpleado">
                            <button onClick={() => setDetalle(!detalle)} className={`${!detalle ? 'buttonFiltroAzul' : 'buttonFiltroGris'}`}>
                                Por empleado
                            </button>
                            <button onClick={() => setDetalle(!detalle)} className={`${detalle ? 'buttonFiltroAzul' : 'buttonFiltroGris'}`}>
                                Todos los empleados
                            </button>
                        </div>
                        {!detalle ? (
                            <div className="inputSelectEmpleado">
                                <SelectEmpleados setUuid={setUuid} />
                            </div>
                        ) : null}
                        <div className="contenedorDesadeHasta">
                            <div>
                                <p>Desde</p>
                                <input className="inputDate" type="date" name="exportDesde" onChange={handleExportChange} />
                            </div>
                            <div>
                                <p>Hasta</p>
                                <input className="inputDate" name="exportHasta" onChange={handleExportChange} type="date" />
                            </div>
                        </div>
                        <div className="contenedorRarioButtons">
                            <div className="contenedorRadio">
                                <input type="radio" value={true} name="detalle" checked={detail === true} onChange={e => setDetail(e.target.value === 'true')} />
                                <p>Con detalle</p>
                            </div>
                            <div className="contenedorRadio">
                                <input type="radio" value={false} name="detalle" checked={detail === false} onChange={e => setDetail(e.target.value === 'true')} />
                                <p>Sin detalle</p>
                            </div>
                        </div>
                        <Button onClick={submitExport} msj="Exportar Excel" tipe="violeta" />
                    </div>
                ) : null}

                <div className="data-table">
                    <table>
                        <thead>
                            <tr>
                                <th className="label-cell" style={{ width: "15%" }}>Nombre</th>
                                <th className="label-cell" style={{ width: "15%" }}>Apellido</th>
                                <th className="label-cell" style={{ width: "15%" }}>Fecha</th>
                                <th className="label-cell" style={{ width: "15%" }}>Hora</th>
                                <th className="label-cell" style={{ width: "15%" }}>Área</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filterTransaction.length === 0 ? (
                                <tr>
                                    <td colSpan="6">No posee registros en el día de la fecha.</td>
                                </tr>
                            ) : (
                                filterTransaction.map((transaction, index) => (
                                    <TableRow key={index} transaction={transaction} index={index} />
                                ))
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Solo mostrar la paginación si hay transacciones listadas */}
                {filterTransaction.length > 0 && (
                    <div className="d-flex justify-content-center mt-4">
                        <CustomPagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Reloj;