import React, { useContext, useEffect, useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import '../../css/AgregarSolicitudModal.css';
import Button from '../../components/Button';
import { getPersona, postRequest, putRequest } from '../../utils/peticionesApi';
import { NewContext } from '../../utils/ContextProvider';

const AgregarSolicitudModal = ({
  reasons,
  rol,
  closeModalSolicitud,
  solicitud,
  handleSubmitSolicitud,
}) => {
  const { getAlert, getId, selectedEmployee } = useContext(NewContext);
  const [request_since, setStartDate] = useState('');
  const [request_expiration, setEndDate] = useState('');
  const [observations_request, setObservations] = useState('');
  const [id_reason, setSelectedMotivo] = useState('');
  const [selectedEmpleadoId, setSelectedEmpleadoId] = useState('');
  const [empleados, setEmpleados] = useState([]);
  const [nombre, setNombre] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (rol === 'ROLE_ADMIN' || rol === 'ROLE_RRHH') {
      listarEmpleados();
    } else {
      setSelectedEmpleadoId(getId);
    }
    if (solicitud) {
      console.log(solicitud);
      setStartDate(formatDateForInput(solicitud.request_since));
      setEndDate(formatDateForInput(solicitud.request_expiration));
      setObservations(solicitud.observations_request);
      setSelectedMotivo(solicitud.id_reason);
      setSelectedEmpleadoId(solicitud.uuid_employee);
      setNombre({
        name: solicitud.employee_first_name,
        lastname: solicitud.employee_last_name,
        id: solicitud.uuid_employee,
      });
      setActive(solicitud.active);
      setIsEditing(true);
    } else {
      setStartDate('');
      setEndDate('');
      setObservations('');
      setSelectedMotivo('');
      setNombre('');
      setSelectedEmpleadoId(rol === 'ROLE_ADMIN' || rol === 'ROLE_RRHH' ? '' : getId);
      setIsEditing(true);
    }
  }, [solicitud]);

  const formatDateForInput = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length !== 3) {
      return '';
    }
    const [year, month, day] = dateArray;
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  };

  const handleMotivoChange = (event) => {
    setSelectedMotivo(event.target.value);
  };

  const handleEstadoChange = (event) => {
    setActive(event.target.value);
  };

  const handleEmpleadoChange = (event) => {
    const empleadoSeleccionado = empleados.find(empleado => empleado.id === event.target.value);
    if (empleadoSeleccionado) {
      const { person, id } = empleadoSeleccionado;
      setNombre({ name: person.name, lastname: person.lastname, id });
      setSelectedEmpleadoId(event.target.value);
    }
  };

  const listarEmpleados = async () => {
    try {
      const response = await getPersona();
      setEmpleados(response.data.content);
    } catch (error) {
      console.error("Error al listar empleados:", error);
    }
  };

  const onSubmit = async () => {
    const formDataToSendSolicitud = {
      active,
      employee_first_name: nombre?.name,
      employee_last_name: nombre?.lastname,
      id_reason: id_reason,
      id_request: solicitud ? solicitud.id_request : 0,
      observations_request: observations_request,
      request_expiration: request_expiration,
      request_since: request_since,
      uuid_employee: nombre?.id,
    };

    try {
      if (!solicitud) {
        const response = await postRequest(formDataToSendSolicitud);
        console.log("Solicitud agregada correctamente:", response);
      } else {
        const response = await putRequest(formDataToSendSolicitud);
        console.log("Editando solicitud existente:", formDataToSendSolicitud);
      }

      handleSubmitSolicitud(formDataToSendSolicitud);
      closeModalSolicitud();

    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <>
      <div className='contenedor-modal-solicitudes'>
        <div>
          {rol === 'ROLE_ADMIN' || rol === 'ROLE_RRHH' ? (
            <div>
              <FormControl variant="outlined" fullWidth>
                <InputLabel
                  id="empleado-label"
                  style={{ textAlign: 'left', marginLeft: '15px' }}
                >
                  Empleados
                </InputLabel>
                <Select
                  labelId="empleado-label"
                  id="empleados"
                  value={selectedEmpleadoId}
                  onChange={handleEmpleadoChange}
                  className="customSelect-solicitudes"
                  disabled={!isEditing}
                  label="Empleados"
                >
                  {empleados ? empleados.map(empleado => (
                    <MenuItem key={empleado.id} value={empleado.id}>
                      {empleado.person.name} {empleado.person.lastname}
                    </MenuItem>
                  )) : null}
                </Select>
              </FormControl>
            </div>
          ) : (
            <div>
              <InputLabel id="solicitante-label">Solicitante</InputLabel>
              <p>{selectedEmployee?.person.name} {selectedEmployee?.person.lastname}</p>
            </div>
          )}
        </div>
        <div className="contenedor-fecha-inicio">
          <TextField
            id="startDate"
            label="Fecha de inicio"
            type="date"
            value={request_since}
            onChange={(event) => setStartDate(event.target.value)}
            className="customTextField"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!isEditing}
            variant="outlined"
          />
        </div>

        <div className="contenedor-fecha-fin">
          <TextField
            id="finishtDate"
            label="Fecha de fin"
            type="date"
            value={request_expiration}
            onChange={(event) => setEndDate(event.target.value)}
            className="customTextField"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!isEditing}
            variant="outlined"
          />
        </div>

        <div className="selectContainer">
          <FormControl>
            <InputLabel id="motivo-label"
              style={{ textAlign: 'left', marginLeft: '15px' }}>Motivos</InputLabel>
            <Select
              labelId="motivo-label"
              id="motivo"
              value={id_reason}
              onChange={handleMotivoChange}
              className="customSelect-solicitudes"
              disabled={!isEditing}
              variant="outlined"
            >
              {reasons ? reasons.map(reason => (
                <MenuItem key={reason.id} value={reason.id}>{reason.reason}</MenuItem>
              )) : null}
            </Select>
          </FormControl>
        </div>
        <div className="selectContainer">
          <FormControl>
            <InputLabel id="estado-label">Estado</InputLabel>
            <Select
              labelId="estado-label"
              id="estado"
              value={active}
              onChange={handleEstadoChange}
              className="customSelect-solicitudes"
              disabled={!isEditing}
              variant="outlined" /* Esto agregará un borde alrededor */
            >
              <MenuItem value={true}>Aprobado</MenuItem>
              <MenuItem value={false}>Pendiente</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="contenedor-observaciones">
          <TextField
            id="observations"
            label="Observaciones"
            multiline
            minRows={4}
            value={observations_request}
            onChange={(event) => setObservations(event.target.value)}
            className="customTextField"
            disabled={!isEditing}
            variant="outlined"
          />
        </div>

      </div>
      <div className="button-solicitud">
        <button className="btn-cancelar" onClick={closeModalSolicitud}>Cancelar</button>
        {solicitud && !isEditing && (
          <button className="btn-editar" onClick={handleEditClick}>Editar</button>
        )}
        {isEditing && (
          <button className="btn-agregar" onClick={onSubmit}>{solicitud ? "Guardar cambios" : "Agregar solicitud"}</button>
        )}
      </div>

    </>
  );
};

export default AgregarSolicitudModal;
