import { React, useEffect, useState ,useContext} from "react";
import "../../css/AgregarObjetivoModal.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../components/Button";
import { getCity, getDistrito, postObjetivo, putObjetivo } from "../../utils/peticionesApi";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag, faLocationDot, faHouse, faBuilding, faUser } from "@fortawesome/free-solid-svg-icons";
import { NewContext } from "../../utils/ContextProvider";

const AgregarObjetivoModal = ({ menuVisible, setMenuVisible, objetivo }) => {
  const { getAlert } = useContext(NewContext);
  const [distrito, setDistrito] = useState(null);
  const [ciudad, setCiudad] = useState(null);
  const navigation = useNavigate()

  const listarDistrito = async () => {
    try {
      const response = await getDistrito();
      setDistrito(response.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  const listarCiudad = async () => {
    try {
      const response = await getCity();
      setCiudad(response.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    listarDistrito()
    listarCiudad()
  }, []);

  const formik = useFormik({
    initialValues: {
      address: {
        apartament: objetivo ? objetivo.address.apartament : "",
        floor: objetivo ? objetivo.address.floor : "",
        number: objetivo ? objetivo.address.number : "",
        street: objetivo ? objetivo.address.street : "",
      },
      city: {
        id_district: objetivo ? objetivo.city.id_district : "",
        name: objetivo ? objetivo.city.name : "",
        zip_code: objetivo ? objetivo.city.zip_code : "",
      },
      objective: {
        name: objetivo ? objetivo.name : "",
      },
    },
    validationSchema: Yup.object({
      address: Yup.object({
        apartament: Yup.string(),
        floor: Yup.number(),
        number: Yup.number(),
        street: Yup.string().required("El campo calle es obligatorio"),
      }),
      city: Yup.object({
        name: Yup.string().required("El campo localidad es obligatorio"),
        zip_code: Yup.number(),
      }),
      objective: Yup.object({
        name: Yup.string().required(
          "El campo nombre del objetivo es obligatorio"
        ),
      }),
    }),
    onSubmit: async (values) => {
      try {
        const dto = {
          address: {
            apartament:
              values.address.apartament === "" ? 0 : values.address.apartament,
            floor: values.address.floor === "" ? 0 : values.address.floor,
            number: values.address.number === "" ? 0 : values.address.number,
            street: values.address.street,
          },
          city: {
            id_district: values.city.id_district === "" ? 0 : values.city.id_district,
            name: values.city.name,
            zip_code: values.city.zip_code === "" ? 0 : values.city.zip_code,
          },
          objective: {
            active: true,
            name: values.objective.name,
          },
        };
        if (objetivo) {
          const dtoUpdate = {
            address: {
              apartament:
                values.address.apartament === "" ? 0 : values.address.apartament,
              floor: values.address.floor === "" ? 0 : values.address.floor,
              number: values.address.number === "" ? 0 : values.address.number,
              street: values.address.street,
              id: objetivo.address.id,
            },
            city: {
              id_district: values.city.id_district === "" ? 0 : values.city.id_district,
              name: values.city.name,
              zip_code: values.city.zip_code === "" ? 0 : values.city.zip_code,
              id: objetivo.city.id,
            },
            objective: {
              active: objetivo.active,
              name: values.objective.name,
              address_id: objetivo.address.id,
              city_id: objetivo.city.id,
              id: objetivo.id
            },
          }
          await putObjetivo(dtoUpdate)
          navigation('/objetivos')
        } else {

          const response = await postObjetivo(dto);

          if (response.status === 200) {
            getAlert({ msj: "Empresa agregado con éxito.", tipe: 'succes' });
            setMenuVisible(false);
          }else{
            getAlert({ msj: 'Error al agregar un objetivo. Intenta nuevamente.', tipe: 'error' });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <div className="contenedorObjetivo">
      <p className="titulo"> {objetivo ? 'Editar puesto' : 'Agregar Empresa'}</p>
      <form
        className="formularioAgregarObjetivo"
        onSubmit={formik.handleSubmit}
      >
        <div className="input-container">
          <FontAwesomeIcon className="icon"
            icon={faUser} />
          <input
            name="objective.name"
            type="text"
            placeholder="Nombre"
            value={formik.values.objective.name}
            onChange={formik.handleChange}
          />
        </div>
        <div className="input-container">
          <FontAwesomeIcon className="icon"
            icon={faLocationDot} />
          <input
            name="address.street"
            type="text"
            placeholder="Calle"
            value={formik.values.address.street}
            onChange={formik.handleChange}
          />
        </div>
        <div className="input-container">
          <FontAwesomeIcon className="icon"
            icon={faHashtag} />
          <input
            name="address.number"
            type="number"
            placeholder="Numeracion"
            value={formik.values.address.number}
            onChange={formik.handleChange}
          />
        </div>
        <div className="input-container">
          <FontAwesomeIcon className="icon"
            icon={faHouse} />
          <input
            name="address.floor"
            type="number"
            placeholder="Piso"
            value={formik.values.address.floor}
            onChange={formik.handleChange}
          />
        </div>
        <div className="input-container">
          <FontAwesomeIcon className="icon"
            icon={faBuilding} />
          <input
            name="address.apartament"
            type="number"
            placeholder="Departamento"
            value={formik.values.address.apartament}
            onChange={formik.handleChange}
          />
        </div>
        <select
          name="city.id_district" // Corregido
          value={formik.values.city.id_district}
          onChange={formik.handleChange}
          className="selectObjetivo"
        >
          <option value="">Selecciona un distrito</option>
          {distrito &&
            distrito.map((distrit) => (
              <option key={distrit.id} value={distrit.id}>
                {distrit.name}
              </option>
            ))}
        </select>

        <select
          name="city.name" // Corregido
          value={formik.values.city.name}
          onChange={formik.handleChange}
          className="selectObjetivo"
        >
          <option value="">Selecciona una ciudad</option>
          {ciudad?.map((city) => (
            <option key={city.id} value={city.name}>
              {city.name}
            </option>
          ))}
        </select>

        <input
          name="city.zip_code"
          type="number"
          placeholder="Codigo postal"
          value={formik.values.city.zip_code}
          onChange={formik.handleChange}
        />
        {
          (formik.errors.address?.street ||
            formik.errors.city?.name ||
            formik.errors.objective?.name) && (
            <div className="contenedorButtonsGuardarObjetivo">
              {formik.errors.address?.street && (
                <p className="errorInputObjetivo">
                  {formik.errors.address?.street}
                </p>
              )}
              {formik.errors.city?.name && (
                <p className="errorInputObjetivo">{formik.errors.city?.name}</p>
              )}
              {formik.errors.objective?.name && (
                <p className="errorInputObjetivo">
                  {formik.errors.objective?.name}
                </p>
              )}
            </div>
          )
        }

        <div className="contenedorButtonsGuardarObjetivo">
          <Button
            onClick={() => setMenuVisible(!menuVisible)}
            tipe="sinBg"
            msj="Cancelar"
          />
          <button className="buttonGuardarObjetivo" type="submit">
            Guardar
          </button>
        </div>
      </form >
    </div >
  );
};

export default AgregarObjetivoModal;
