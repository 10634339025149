import React, { useContext, useEffect, useState } from 'react';
import CardHome from '../components/CardHome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faBuildingUser, faClock, faFaceFrownOpen, faUser } from '@fortawesome/free-solid-svg-icons';
import MetricsHome from '../components/MetricsHome';
import { getBirdays, getStadisctic, getStadiscticArrival, getStatus } from '../utils/peticionesApi';
import { NewContext } from '../utils/ContextProvider';
import CardArrivals from '../components/CardArrivals';
import CardBirthday from '../components/CardBirthday';

const Home = () => {
  const { selectedEmployee, getRol } = useContext(NewContext);
  const [dataCard, setDataCard] = useState();
  const [dataStatus, setDataStatus] = useState();
  const [arrivals, setArrivals] = useState();
  const [dateBirthdays, setDateBirthdays] = useState();

  useEffect(() => {
    if (getRol === 'ROLE_ADMIN' || getRol === 'ROLE_RRHH' || getRol === 'ROLE_SUPERVISOR') {
      stadiscics();
      status();
      stadiscicsArray();
      birthdays();
    }
  }, [getRol]);

  const stadiscics = async () => {
    try {
      const res = await getStadisctic();
      setDataCard(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const stadiscicsArray = async () => {
    try {
      const res = await getStadiscticArrival();
      setArrivals(res.data.slice(0, 4));
    } catch (error) {
      console.error(error);
    }
  };

  const status = async () => {
    try {
      const res = await getStatus();
      setDataStatus(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const birthdays = async () => {
    try {
      const res = await getBirdays();
      setDateBirthdays(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  if (!(getRol === 'ROLE_ADMIN' || getRol === 'ROLE_RRHH' || getRol === 'ROLE_SUPERVISOR')) {
    return null; // No mostrar nada si el usuario no tiene uno de los roles permitidos
  }

  return (
    <div className='contenedorHome'>
      <div className="holaTitulo">
        <span className='holaTituloColor'>¡Hola!</span>
        <span className="nombreTitulo">
          {selectedEmployee && `${selectedEmployee?.person.name} ${selectedEmployee?.person.lastname}`}
        </span>
      </div>
      <div className='contenedorCardsHome'>
        <CardHome msj='Total de trabajadores' number={dataStatus?.employee}>
          <FontAwesomeIcon icon={faUser} className='iconCardHome' />
        </CardHome>
        <CardHome msj='Empleados que trabajan hoy' number={dataCard?.workedDays}>
          <FontAwesomeIcon icon={faBriefcase} className='iconCardHome2' />
        </CardHome>
        <CardHome msj='Empleados ausentes' number={dataCard?.absences}>
          <FontAwesomeIcon icon={faFaceFrownOpen} className='iconCardHome3' />
        </CardHome>
        <CardHome msj='Llegadas tardes' number={dataStatus?.lateEmployees}>
          <FontAwesomeIcon icon={faClock} className='iconCardHome4' />
        </CardHome>
        <CardHome msj='Visitantes externos' number={dataStatus?.visitor}>
          <FontAwesomeIcon icon={faBuildingUser} className='iconCardHome5' />
        </CardHome>
      </div>
      <div className='contenedorCardsHome'>
        <CardHome>
          <p className='titleCard'>Próximas celebraciones</p>
          <CardBirthday birthdays={dateBirthdays} />
        </CardHome>
        <CardHome>
          <p className='titleCard'>Metricas generales</p>
          <MetricsHome asistencias={dataCard?.assistentPercentage} ausencias={dataCard?.absencesPercentage} tardanzas={dataCard?.lateArrivalsPercentage} noFicharon={dataCard?.absencesPercentage} />
        </CardHome>
        <CardHome>
          <p className='titleCard'>Top tardanzas</p>
          <CardArrivals arrivals={arrivals} />
        </CardHome>
      </div>
    </div>
  );
};

export default Home;