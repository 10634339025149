import React, { useState } from 'react';
import "../../css/ImportarEmpleadoModal.css";


const ImportarEmpleadoModal = ({ showModal, closeModal }) => {
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = () => {
        if (!file) {
            alert("Selecciona un archivo antes de importar.");
            return;
        }
        console.log("Archivo importado:", file);
    };

    return (
        showModal && (
            <div className="modal-overlay">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Importar Empleados</h2>
                        <button onClick={closeModal} className="close-button">X</button>
                    </div>
                    <div className="modal-body">
                        <label htmlFor="file-upload" className="file-upload">
                            Archivo de importación:
                            <input type="file" id="file-upload" onChange={handleFileChange} />
                        </label>
                        <p className="template-download">
                            <a href="/ruta-de-la-plantilla.xlsx" download>Descargar plantilla</a>
                        </p>
                        <div className="table-preview">
                            <table>
                                <thead>
                                    <tr>
                                        <th>ID del Empleado</th>
                                        <th>Nombre</th>
                                        <th>Apellido</th>
                                        <th>Código departamento</th>
                                        <th>Nombre del departamento</th>
                                        <th>Código de Cargo</th>
                                        <th>Nombre del Cargo</th>
                                        <th>Género</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>10001</td>
                                        <td>Koi</td>
                                        <td>Start</td>
                                        <td>1</td>
                                        <td>HR</td>
                                        <td>1</td>
                                        <td>Director</td>
                                        <td>Masculino</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="description">
                            <p>1. El encabezado en el archivo de plantilla es requerido</p>
                            <p>2. El ID Empleado, Nombres, Número departamento son campos requeridos</p>
                            <p>3. Todos los valores de la columna deben ser en formato de TEXTO</p>
                            <p>4. El número de la tarjeta debe ser único</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={handleSubmit} className="confirm-button">Confirmar</button>
                        <button onClick={closeModal} className="cancel-button">Cancelar</button>
                    </div>
                </div>
            </div>
        )
    );
};

export default ImportarEmpleadoModal;
