import { React, useContext, useEffect, useState } from "react";
import '../css/Home.css';
import '../css/Asistencia.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CustomPagination from '../components/CustomPagination';
import MainMenu from '../components/mainMenu';
import { NewContext } from "../utils/ContextProvider";
import OptionComponent from '../components/OptionComponent';
import { getAsistencia, getExportWithDetail, getExportWithOutDetail, getListPersona } from "../utils/peticionesApi";
import Button from "../components/Button";
import SelectEmpleados from "../components/SelectEmpleados";

const Asistencia = () => {
  const {getCompany, getAlert, getId} = useContext(NewContext)
  const [empleados, setEmpleados] = useState([]);
  const [personas, setPersonas] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [dateExport, setDateExport] = useState({
    exportDesde: null,
    exportHasta: null
  })
  const [uuid, setUuid] = useState(null)
  const [modalView, setModalView] = useState(false);
  const [modalViewExport, setModalViewExport] = useState(false);
  const [detalle, setDetalle] = useState(true)
  const [detail, setDetail] = useState(false)
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dateStringInicio = selectedStartDate.toDateString();
  const formattedDateInicio = formatDate(dateStringInicio);
  const dateStringFin = selectedEndDate.toDateString();
  const formattedDateFin = formatDate(dateStringFin);
  const employeesPerPage = 20; 

  function formatDate(dateString) {
    const months = {
      Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
      Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
    };
    //console.log(dateString.toDateString())
    const [nameDay, monthStr, day, year] = dateString.split(' ');
    const month = months[monthStr];

    return `${day}/${month}/${year}`;
  }

  const handleDateChange = newDates => {
    if (newDates.length === 1) {
      setSelectedStartDate(newDates[0]);
      setSelectedEndDate(null);
    } else if (newDates.length === 2) {
      setSelectedStartDate(newDates[0]);
      setSelectedEndDate(newDates[1]);
      setModalView(!modalView)
    }
  };

  const handleExportChange = e => {
    const [año, mes, dia] = e.target.value.split('-')
    setDateExport({...dateExport, [e.target.name]: `${dia}/${mes}/${año}`})
  }
  
  
  const submitExport =async()=> {
    if(!dateExport.exportDesde || !dateExport.exportHasta) return getAlert({ msj: `No se ingreso la fecha.`, tipe: 'error' });
    try {
      const peticion = detail ? getExportWithDetail(getCompany, dateExport.exportDesde, dateExport.exportHasta, uuid) : getExportWithOutDetail(getCompany, dateExport.exportDesde, dateExport.exportHasta, uuid)
      const res = await peticion
      const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'asistencias.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error)
    }
  }

  const handlePopupOpen = (empleado) => {
    setSelectedEmployee(empleado);
    //setPersonas(empleado.uuid)
    setShowPopup(true);
  };


  const listarEmpleados = async (currentPage, employeesPerPage) => {
    try {
      const response = await getAsistencia(formattedDateInicio, formattedDateFin, currentPage, employeesPerPage)
      setEmpleados(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.log(error)
    }
  };

  const listarPersonas = async (id) => {
    try {
      const response = await getListPersona(id)
      setPersonas(response.data); 
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    listarEmpleados(currentPage);
    if (showPopup && selectedEmployee) {
      if (searchTerm !== '') {
        listarEmpleados(0, 300);
      } else {
        // Si no hay término de búsqueda, cargar todas las personas paginadas
        listarPersonas(currentPage, employeesPerPage);
      }
      listarPersonas(selectedEmployee.uuid);
    }
  }, [showPopup, selectedEmployee, currentPage, selectedEndDate]);
  // Filtrar empleados por nombre o apellido
  const filteredEmpleados = empleados.filter(empleado => {
    const fullName = empleado.name + ' ' + empleado.lastname;
    return fullName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
        <>
          <div className='asistcontainer'>
            <p className="titulo">Asistencias</p>
            <OptionComponent type="text" placeHolder="Buscar por nombre, apellido o DNI" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} modalView={modalView} setModalView={setModalView} modalViewExport={modalViewExport} setModalViewExport={setModalViewExport}/> 
            {
              modalView ?
                <div className="modalCalendar">
                  <Calendar onChange={handleDateChange} value={selectedEndDate ? [selectedStartDate, selectedEndDate] : selectedStartDate} selectRange={true} />
                </div>
              :
              null
            }
            {
              modalViewExport ?
              <div className="modalExport">
                  <div className="contenedorBotonesFiltroEmpleado">
                    <button onClick={()=> setDetalle(!detalle)} className={`${!detalle ? 'buttonFiltroAzul' : 'buttonFiltroGris'}`}>Por empleado</button>
                    <button onClick={()=> setDetalle(!detalle)} className={`${detalle ? 'buttonFiltroAzul' : 'buttonFiltroGris'}`}>Todos los empleados</button>
                  </div>
                  {
                    !detalle ? 
                    <div className="inputSelectEmpleado">
                      <SelectEmpleados setUuid={setUuid}/> 
                    </div>
                    : null
                  }
                <div className="contenedorDesadeHasta">
                  <div>
                    <p>Desde</p>
                    <input className="inputDate" type="date" name='exportDesde' onChange={handleExportChange}/>
                  </div>
                  <div>
                    <p>Hasta</p>
                    <input className="inputDate" name='exportHasta' onChange={handleExportChange} type="date"/>
                  </div>
                </div>
                <div className="contenedorRarioButtons">
                  <div className="contenedorRadio">
                    <input type="radio" value={true} name="detalle" checked={detail === true} onChange={e=> setDetail(e.target.value === 'true')}/>
                    <p>Con detalle</p>
                  </div>
                  <div className="contenedorRadio">
                    <input type="radio" value={false} name='detalle' checked={detail === false} onChange={e=> setDetail(e.target.value === 'true')}/>
                    <p>Sin detalle</p>
                  </div>
                </div>
                <Button onClick={submitExport} msj='Exportar Excel' tipe='violeta'/>
              </div>
              : null
            }
            <div className="data-table">
              <table>
                <thead>
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Apellido</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Hora</th>
                    <th scope="col">Lugar y Puesto</th>
                    <th scope="col">Ciudad</th>
                    <th scope="col">Evento</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEmpleados.length === 0 ? (
                    <tr>
                      <td colSpan="7">No se encontraron resultados.</td>
                    </tr>
                  ) : (filteredEmpleados.map((empleado, index) => (
                    <tr key={index} onClick={() => { handlePopupOpen(empleado); listarPersonas(empleado.uuid)}}>
                      {/* <td>Falta</td> */}
                      <td className="align-middle">{empleado.name}</td>
                      <td className="align-middle">{empleado.lastname}</td>
                      <td className="align-middle">{empleado.date}</td>
                      <td className="align-middle">{empleado.registry}</td>
                      <td className="align-middle" style={{display: 'flex', flexDirection: 'column'}}>
                        {empleado.nameObjective}
                        <span style={{ color: '#6C757D', fontSize: '14px' }}>{empleado.namePlace}</span>
                      </td>
                      <td className="align-middle">{empleado.cityObjective}</td> {/* Agregado para la ciudad */}
                      <td className="align-middle">
                        {empleado.type === 'I' ? (
                          <span className="spanIngreso">Ingreso</span>
                        ) : empleado.type === 'E' ? (
                          <span className="spanEgreso">Egreso</span>
                        ) : (
                          <span className="spanAusente">Ausente</span>
                        )}
                      </td>
                    </tr>
                  )))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </>
  );
};

export default Asistencia;