import React, { useContext, useEffect, useState, useMemo } from "react";
import "react-calendar/dist/Calendar.css";
import "../css/Solicitudes.css";
import CustomPagination from "../components/CustomPagination";
import { NewContext } from "../utils/ContextProvider";
import { getRequestById } from "../utils/peticionesApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";

const Ausencia = () => {
    const { getToken, getId } = useContext(NewContext);
    const [ausencias, setAusencias] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (getToken && getId) {
            listarAusencias();
        }
    }, [getToken, getId]);

    const listarAusencias = async () => {
        try {
            const response = await getRequestById(getId);
            if (response && response.data) {
                setAusencias(response.data);
            } else {
                console.error("Error: La respuesta no contiene datos.");
            }
        } catch (error) {
            if (error.response) {
                console.error(`Error en la solicitud: ${error.response.status} - ${error.response.statusText}`);
            } else {
                console.error("Error al hacer la solicitud:", error.message);
            }
        }
    };

    const formatDate = (dateArray) => {
        if (!Array.isArray(dateArray) || dateArray.length !== 3) {
            return "Fecha inválida";
        }
        const [year, month, day] = dateArray;
        return `${day}/${month}/${year}`;
    };

    const filtradoAusencias = useMemo(() => {
        return ausencias.filter((a) =>
            a.observations_request?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, ausencias]);

    const ausenciasPorPagina = 15;
    const paginatedAusencias = useMemo(() => {
        const start = currentPage * ausenciasPorPagina;
        const end = start + ausenciasPorPagina;
        return filtradoAusencias.slice(start, end);
    }, [currentPage, filtradoAusencias]);

    const TableRow = ({ ausencia }) => {
        return (
            <tr>
                <td>{ausencia.employee_first_name}</td>
                <td>{ausencia.employee_last_name}</td>
                <td>{formatDate(ausencia.date)}</td>
            </tr>
        );
    };

    return (
        <div className="contenedor-solicitudes">
            <button
                onClick={() => window.history.back()}
                className="buttonVolverObj"
            >
                <FontAwesomeIcon icon={faCircleLeft} />
                Volver
            </button>
            <p className="titulo">Ausencias</p>
            <div className="data-table">
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Fecha</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedAusencias.length === 0 ? (
                            <tr>
                                <td colSpan="3">No se encontraron resultados.</td>
                            </tr>
                        ) : (
                            paginatedAusencias.map((ausencia, index) => (
                                <TableRow key={index} ausencia={ausencia} />
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            <div className="d-flex justify-content-center mt-4">
                <CustomPagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(filtradoAusencias.length / ausenciasPorPagina)}
                    setCurrentPage={setCurrentPage}
                    hasNextPage={currentPage < Math.ceil(filtradoAusencias.length / ausenciasPorPagina) - 1}
                />
            </div>
        </div>
    );
};

export default Ausencia;