import React, { useState } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import CustomPagination from './CustomPagination'; // Importa tu componente de paginación
import '../css/ModalConTabla.css';

const ModalConTabla = ({ show, handleClose, data }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5; // Cantidad de registros por página

    // Lógica de paginación
    const totalPages = Math.ceil(data.length / itemsPerPage); // Calcula el número total de páginas
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = data.slice(startIndex, endIndex);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            backdrop="static"
            keyboard={false}
            dialogClassName="custom-modal"
            style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2000 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Notificaciones</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Nombre y Apellido</th>
                            <th>Fecha</th>
                            <th>Hora Ingreso</th>
                            <th>Ciudad</th>
                            <th>Objetivo</th>
                            <th>Puesto</th>
                            <th>Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.length > 0 ? (
                            paginatedData.map((empleado, index) => (
                                <tr key={index}>
                                    <td>{empleado.name} {empleado.lastname}</td>
                                    <td>{empleado.date}</td>
                                    <td>{empleado.time}</td>
                                    <td>{empleado.city.name}</td>
                                    <td>{empleado.objective.name}</td>
                                    <td>{empleado.place.name}</td>
                                    <td>{empleado.type === "I" ? "Ingreso" : "Egreso"}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" className="text-center">
                                    No hay datos disponibles
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {/* Componente de paginación */}
                <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalConTabla;
