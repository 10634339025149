import React, { useState } from 'react'
import '../../css/EditEmpleado.css'
import Button from '../../components/Button'
import { putPerfil } from '../../utils/peticionesApi'

const initialState= {
    address_dto: {
      apartament: "",
      floor: "",
      number: "",
      street: "",
    },
    cell_phone: "",
    email: "",
    emergency_contact: "",
    name_emergency_contact: "",
  }

const EditEmpleado = ({setEditMode, persona, id, setPersona}) => {
    const [editedInfo, setEditedInfo] = useState(persona ? {
        address_dto: {
            apartament: persona.address?.apartament,
            floor: persona.address?.floor,
            number: persona.address?.number,
            street: persona.address?.street,
          },
          cell_phone: persona?.cell_phone,
          email: persona?.email,
          emergency_contact: persona?.emergency_contact,
          name_emergency_contact: persona?.name_emergency_contact,
    } : initialState);

      const handleSaveClick = async () => {
        try {
            const response = await putPerfil(id, editedInfo)
            setPersona(response.data)
            setEditMode(false)
        } catch (error) {
            console.log(error)
        }
        /*  const config = {
           headers: {
             Authorization: `Bearer ${session}` // Asumiendo que session es el token de autorización
           },
           params: {
             uuid: getId
           }
         };
     
         
         axios.put(urlPersonas + '/' + getId, data, config)
           .then(response => {
             setPersona(response.data); // Guardar los empleados en el estado
           })
           .catch(error => {
             console.log(error);
           });
     
         // Después de guardar, sal del modo de edición
         setEditMode(false); */
       };

       const handleInputChange = (e, field, nestedField) => {
        setEditedInfo((prevInfo) => ({
          ...prevInfo,
          [field]: nestedField ? { ...prevInfo[field], [nestedField]: e.target.value } : e.target.value,
        }));
        console.log(editedInfo)
      };

  return (
    <div className='contenedor-editEmpleado'>
        <p className="titulo-edit-empleado">Editar empleado</p>
        <div className='borde-bottom-edit'>
            <p className="subtitulo-edit-empleado">Direccion</p>
            <div className='contenedorDireccion'>
                <div>
                    <p>Calle: </p>
                    <input className='input-edit-empleado' value={editedInfo.address_dto.street} onChange={(e) => handleInputChange(e, 'address_dto', 'street')}/>
                </div>
                <div>
                    <p>Numero: </p>
                    <input className='input-edit-empleado' value={editedInfo.address_dto.number} onChange={(e) => handleInputChange(e, 'address_dto', 'number')}/>
                </div>
                <div>
                    <p>Piso: </p>
                    <input className='input-edit-empleado' value={editedInfo.address_dto.floor} onChange={(e) => handleInputChange(e, 'address_dto', 'floor')}/>
                </div>
                <div>
                    <p>Departamento: </p>    
                    <input className='input-edit-empleado' value={editedInfo.address_dto.apartament} onChange={(e) => handleInputChange(e, 'address_dto', 'apartament')}/>
                </div>
            </div>
        </div>
        <div className='borde-bottom-edit'>
            <p className="subtitulo-edit-empleado">Contacto</p>
            <div className='contenedorDireccion'>
                <div>
                    <p>Telefono:</p>
                    <input className='input-edit-empleado' value={editedInfo.cell_phone} onChange={(e) => handleInputChange(e, 'cell_phone')}/>
                </div>
                <div>
                    <p>Email:</p>
                    <input className='input-edit-empleado' value={editedInfo.email} onChange={(e) => handleInputChange(e, 'email')}/>
                </div>
                <div>
                    <p>Nombre contacto:</p>
                    <input className='input-edit-empleado' value={editedInfo.name_emergency_contact} onChange={(e) => handleInputChange(e, 'name_emergency_contact')}/>
                </div>
                <div>
                    <p>Telefono contacto:</p>
                    <input className='input-edit-empleado' value={editedInfo.emergency_contact} onChange={(e) => handleInputChange(e, 'emergency_contact')}/>
                </div>
            </div>
        </div>
        <div className='contenedor-button-edit-empleado'>
            <Button msj='Cancelar' tipe='sinBg' onClick={()=> setEditMode(false)}/>
            <Button msj='Guardar' onClick={handleSaveClick}/>
        </div>
    </div>
  )
}

export default EditEmpleado
