import { useEffect, useState, useContext } from 'react';
import { getFolderContents, downloadFile, postFolders } from '../utils/peticionesApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile, faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import OptionComponentDocuments from "../forms/Documentos/OptionComponentDocuments";
import '../css/Documentos.css';
import Button from "../components/Button";
import { NewContext } from "../utils/ContextProvider";

const esArchivo = (nombre) => {
    const extensionesArchivos = ['.xls', '.xlsx', '.txt', '.pdf', '.doc', '.docx', '.jpg', '.png'];
    return extensionesArchivos.some(ext => nombre.toLowerCase().endsWith(ext));
};

const Documentos = () => {
    const [contenidos, setContenidos] = useState([]);
    const [currentFolder, setCurrentFolder] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [rutaActual, setRutaActual] = useState([]);
    const [modalViewExport, setModalViewExport] = useState(false);
    const [modalView, setModalView] = useState(false);
    const { getAlert } = useContext(NewContext);
    const [modalCreateFolder, setModalCreateFolder] = useState(false);
    const [newFolderName, setNewFolderName] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        if (!modalViewExport) {
            setSelectedItems([]);
        }
    }, [modalViewExport]);

    const listarContenidos = async (folder) => {
        try {
            const folderPath = folder || '';
            const resp = await getFolderContents(folderPath);
            if (Array.isArray(resp)) {
                setContenidos(resp);
            } else {
                console.error('Respuesta inesperada:', resp);
                setContenidos([]);
            }
        } catch (error) {
            console.error('Error al listar carpetas:', error);
            setContenidos([]);
        }
    };

    useEffect(() => {
        listarContenidos('');
    }, []);

    const abrirCarpeta = (folderName) => {
        const nuevaCarpeta = currentFolder ? `${currentFolder}/${folderName}` : folderName;
        setRutaActual([...rutaActual, folderName]);
        setCurrentFolder(nuevaCarpeta);
        listarContenidos(nuevaCarpeta);
    };

    const descargarArchivo = (fileName) => {
        const fullFilePath = currentFolder ? `${currentFolder}/${fileName}` : fileName;
        downloadFile(fullFilePath);
    };

    const handleSelectItem = (item) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter(i => i !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };

    const descargarSeleccionados = () => {
        if (selectedItems.length === 0) {
            getAlert({ msj: 'No has seleccionado ningún archivo o carpeta', tipe: 'warning' });
            return;
        }
        selectedItems.forEach(item => {
            descargarArchivo(item.name);
        });
        setSelectedItems([]);
    };

    const contenidosFiltrados = Array.isArray(contenidos)
        ? contenidos.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : [];

    const volverAtras = () => {
        if (rutaActual.length > 0) {
            const nuevaRuta = [...rutaActual];
            nuevaRuta.pop();
            const nuevaCarpeta = nuevaRuta.join('/') || '';
            setRutaActual(nuevaRuta);
            setCurrentFolder(nuevaCarpeta);
            listarContenidos(nuevaCarpeta);
        }
    };

    const handleExportChange = e => {
    };

    const handleCreateFolder = async () => {
        try {
            if (newFolderName) {
                const response = await postFolders(newFolderName);

                if (response.status === 200) {
                    getAlert({ msj: "Carpeta creada con éxito.", tipe: 'succes' });
                    setNewFolderName('');
                    setModalCreateFolder(false);
                    listarContenidos(currentFolder);
                } else {
                    getAlert({ msj: response.data.message, tipe: 'error' });
                }
            } else {
                getAlert({ msj: "El nombre de la carpeta no puede estar vacío.", tipe: 'warning' });
            }
        } catch (error) {
            console.error('Error al crear la carpeta:', error);
            getAlert({ msj: "Error al crear la carpeta.", tipe: 'error' });
        }
    };

    return (
        <div className="contenedor-documentos">
            <div>
                <p className="titulo">Mis documentos</p>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {rutaActual.length > 0 && (
                        <button
                            onClick={() => volverAtras()}
                            className="buttonVolverObj"
                        >
                            <FontAwesomeIcon icon={faCircleLeft} />
                            Volver
                        </button>
                    )}
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="searchbar-input-wrap">
                        <OptionComponentDocuments
                            type="text"
                            placeHolder="Buscar por nombre, archivo"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            value={searchTerm}
                            modalView={modalView}
                            setModalView={setModalView}
                            modalViewExport={modalViewExport}
                            setModalViewExport={setModalViewExport}
                            setModalCreateFolder={setModalCreateFolder}
                        />
                        {
                            modalCreateFolder && (
                                <div className="modalCreateFolder">
                                    <div className="contenedorCrearCarpeta">
                                        <p>Crear nueva carpeta</p>
                                        <input
                                            type="text"
                                            value={newFolderName}
                                            onChange={(e) => setNewFolderName(e.target.value)}
                                            placeholder="Nombre de la carpeta"
                                            className="inputCreateFolder"
                                        />
                                        <Button onClick={handleCreateFolder} msj="Crear carpeta" tipe="violeta" />
                                        <Button onClick={() => setModalCreateFolder(false)} msj="Cancelar" tipe="gris" />
                                    </div>
                                </div>
                            )
                        }
                        {
                            modalViewExport && (
                                <div className="modalExport">
                                    <p>Selecciona los archivos y carpetas que deseas descargar:</p>
                                    <Button onClick={descargarSeleccionados} msj='Descargar seleccionados' tipe='violeta' />
                                    <Button onClick={() => setModalViewExport(false)} msj='Cerrar' tipe='gris' />
                                </div>
                            )
                        }
                    </div>
                </div>

                <div className="carpetas-container">
                    {contenidosFiltrados.length > 0 ? (
                        contenidosFiltrados.map((contenido, index) => (
                            <div
                                key={index}
                                className={`carpeta-card ${selectedItems.includes(contenido) ? 'selected' : ''}`}
                                onClick={() => {
                                    if (modalViewExport) {
                                        handleSelectItem(contenido);
                                    }
                                }}
                            >
                                <div className="carpeta-icono">
                                    <FontAwesomeIcon icon={esArchivo(contenido.name) ? faFile : faFolder} size="3x" />
                                </div>
                                <p className="nombre-carpeta">{contenido.name}</p>
                            </div>
                        ))
                    ) : (
                        <p>No se encontraron carpetas ni archivos</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Documentos;